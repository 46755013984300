import { useSelector } from 'react-redux'
import { StyledFooter, Column, SocialIcons, SocialIcon } from './Footer.Styled'
import Container from '../../Container/Container'
import Flex from '../../Flex/Flex'
import Text from '../../Text/Text'
import Link from '../../Link/Link'
import instagram from '../../../../assets/image/footer/instagram.svg'
import linkedin from '../../../../assets/image/footer/linkedin.svg'
import tiktok from '../../../../assets/image/footer/tiktok.svg'

const Footer = () => {
  const translation = useSelector((s) => s.translation)
  const theme = useSelector((s) => s.theme)

  return (
    <StyledFooter>
      <Container>
        <Flex gap="25px" margin={'0 0 15px 0'}>
          <Column>
            <Text text={translation.contact.street} size={theme.size.sm} color={theme.color.blue} />
            <Text
              text={`${translation.contact.postcode} ${translation.contact.city}`}
              size={theme.size.sm}
              color={theme.color.blue}
            />
          </Column>
          <Column style={{ textAlign: 'center' }}>
            <Link
              href="/imprint"
              text={translation.imprint.headline}
              size={theme.size.sm}
              color={theme.color.blue}
            />
          </Column>
          <Column style={{ textAlign: 'center' }}>
            <Link
              href="/data-protection"
              text={translation.dataProtection.headline}
              size={theme.size.sm}
              color={theme.color.blue}
            />
          </Column>
          <Column>
            <SocialIcons>
              <SocialIcon
                target="_blank"
                href="https://www.instagram.com/hilpert.hausverwaltung/"
                style={{ backgroundImage: `url(${instagram})` }}
              />
              <SocialIcon
                target="_blank"
                href="https://www.linkedin.com/company/wilhelm-hilpert-hausverwaltung/"
                style={{ backgroundImage: `url(${linkedin})` }}
              />
              <SocialIcon
                target="_blank"
                href="https://www.tiktok.com/@hilpert.hausverwaltung"
                style={{ backgroundImage: `url(${tiktok})` }}
              />
            </SocialIcons>
          </Column>
        </Flex>
        <Text
          color={theme.color.lightblue}
          size={theme.size.xs}
          align={'center'}
          text={`Wilhelm Hilpert Hausverwaltung GmbH©${new Date().getFullYear()}`}
        />
      </Container>
    </StyledFooter>
  )
}

export default Footer
