import styled from 'styled-components'

export const StyledFooter = styled.div`
  display: flex;
  width: 100%;
  padding: 50px 0px;
  align-items: center;
`

export const Column = styled.div`
  flex: 1 1 0px;
`

export const SocialIcons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
`

export const SocialIcon = styled.a`
  width: 25px;
  height: 25px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`
