const theme = {
  size: {
    xs: '16px',
    sm: '18px',
    md: '26px',
    lg: '34px',
    xlg: '42px',
  },
  color: {
    black: 'black',
    white: 'white',
    lightblue: 'rgb(160,173,203)',
    blue: 'rgb(63,72,121)',
    gray: 'rgb(236,237,242)',
    red: '#E74C3C',
  },
  breakpoint: {
    sm: '768px',
    md: '992px',
    lg: '1200px',
  },
  border: {
    radius: '10px',
  },
  shadow: '2px 2px 16px -1px rgba(0,0,0,0.48)',
}

module.exports = theme
