//
// ONLY EDIT THIS FILE IN /frontend/src/
//

const translation = {
  home: {
    headline: 'Startseite',
    title: 'Herzlich willkommen bei der Wilhelm Hilpert Hausverwaltung GmbH!',
    text: 'Wir sind Ihr kompetenter Ansprechpartner rund um die Verwaltung von Wohneigentum, Mietwohnungen und Gewerbeflächen. Profitieren Sie von unserer 25-jährigen Erfahrung in der Branche!',
    search: {
      title: 'SIE HABEN FRAGEN ZU IHRER IMMOBILIE ODER BENÖTIGEN MEHR INFORMATIONEN DAZU?',
      text: 'GEBEN SIE HIER DIE ADRESSE IN DAS SUCHFELD EIN:',
      inputTitle: 'STRAßE, HAUSNUMMER',
      cta: 'Anzeigen',
    },
    overview: {
      headline: 'Unsere Immobilien',
      intro1: 'BETREUEN WIR IHRE IMMOBILIE BEREITS?',
      intro2: 'HIER FINDEN SIE EINE ÜBERSICHT ALLER VON UNS BETREUTEN OBJEKTE:',
      intro3: 'WÄHLEN SIE EINE IMMOBILIE AUS:',
      text1: 'WIR SIND FÜR SIE DA – ALS IHR KOMPETENTER ANSPRECHPARTNER',
      text2:
        'UNSER TEAM IN BERLIN BESTEHT AUS ERFAHRENEN MITARBEITER*INNEN, DIE SIE BESTENS BERATEN KÖNNEN!',
      contact: 'Zum Kontakt',
    },
    aboutUs: {
      headline: 'Über uns',
      row1: [
        'Die Wilhelm Hilpert Hausverwaltung GmbH wurde Anfang 1997 gegründet. Wir haben unsere Arbeit in einem Büro in der Fehrbelliner Straße in Mitte, mit einem kleinen Team, begonnen. Unsere ersten Verwaltungsobjekte waren fast ausschließlich Mietshäuser im Stadtteil Mitte.',
        'Nach und nach wurden weitere Verwaltungen, mit allen dazugehörigen Belangen, übernommen. Dadurch sind unser Aufgabengebiet und Aufgabenumfang sehr schnell gewachsen.',
        'In den Folgejahren vergrößerte sich unser Bestand sichtlich. Um eine gute Betreuung unserer Mieter:Innen und Eigentümer:Innen gewährleisten zu können, vergrößerte sich auch schnell unser Team. Alle Mitarbeiter:Innen haben eine qualifizierte Ausbildung in ihrem Tätigkeitsbereich.',
        'Inzwischen verwalten wir auch vereinzelte Objekte in Prenzlauer Berg, Pankow, Treptow-Köpenick, Grünau, Kreuzberg und Friedrichshain.',
      ],
      row2: [
        'Eine Vielzahl von Eigentümern schenkt uns ihr Vertrauen. Der größte Teil der durch uns verwalteten Immobilien sind Wohnungseigentümergemeinschaften mit gleichzeitiger Sondereigentumsverwaltung. Nach wie vor haben wir aber auch „normale“ Mietshäuser in unserem Bestand sowie gewerbliche Objekte. Zusammengefasst betreuen wir zur Zeit mehr als 5.000 Verwaltungseinheiten.',
        'Durch die kontinuierlich steigende Zahl an Verwaltungseinheiten reichten unsere Räumlichkeiten nicht mehr aus und wir zogen im Oktober 2005 in ein größeres und moderneres Büro, welches zwischenzeitlich durch den starken Zuwachs an zu verwaltenden Objekten zu klein wurde. Im November 2014 sind wir in unser neues und wiederum größeres Büro in Friedrichshain gezogen.',
        'Unser Bemühen ist es, das unsere Mieter:Innen und Eigentümer:Innen langfristig mit unserer Leistung zufrieden sind und wir diese stetig für Sie verbessern können.',
      ],
    },
    office: {
      headline: 'Unser Büro',
    },
    officeHours: {
      headline: 'Unsere Sprechzeiten',
      sections: [
        {
          title: 'Telefonische Sprechzeit:',
          content: 'Mo, Di, Do und Fr: 9:00-13:00 Uhr',
        },
        {
          title: 'Telefonische und persönliche Sprechzeit:',
          content: 'Mi: 10-17 Uhr',
        },
      ],
    },
  },

  contact: {
    headline: 'Kontakt',
    message: {
      placeholder: 'Ihre Nachricht...',
    },
    cta: 'Absenden',
    street: 'Deutschmeisterstraße 16',
    city: 'Berlin',
    postcode: '10367',
    submit: {
      success: 'Ihre Kontaktanfrage wurde erfolgreich verschickt.',
      error: 'Leider ist ein technischer Fehler aufgetreten.',
      subject: 'Neue Kontaktanfrage',
    },
  },

  damageAndInsurance: {
    nav: 'Schadens- und Versicherungsmanagement',
    headline: 'Schadens- und Versicherungsmanagement',
    cards: [
      {
        name: 'Alexander Nieland',
        department: 'Schadens- und Versicherungsmanagement',
        company: 'Wilhelm Hilpert Hausverwaltung GmbH',
        street: 'Deutschmeisterstraße 16',
        postcode: '10367',
        city: '10367 Berlin',
        phone: '0 30 / 44 38 43 - 130',
        fax: '0 30 / 44 38 43 - 299',
        mobile: '0152 / 28 56 90 15',
        mail: ['a.nieland@hilpert-hausverwaltung.de', 'schaden@hilpert-hausverwaltung.de'],
      },
      {
        name: 'Timon Lettau',
        department: 'Schadens- und Versicherungsmanagement',
        company: 'Wilhelm Hilpert Hausverwaltung GmbH',
        street: 'Deutschmeisterstraße 16',
        postcode: '10367',
        city: '10367 Berlin',
        phone: '0 30 / 44 38 43 - 230',
        fax: '0 30 / 44 38 43 - 299',
        mobile: '0152 / 28 57 05 92',
        mail: ['t.lettau@hilpert-hausverwaltung.de', 'schaden@hilpert-hausverwaltung.de'],
      },
    ],
  },

  estates: {
    headline: 'Immobilien',
    text: 'Für alle Fragen rund um Ihre Wohnung/Immobilie sind wir die richtigen Ansprechpartner. Gerne helfen wir Ihnen weiter.',
    search: {
      placeholder: 'Suche...',
    },
    showAllToggle: 'Alle anzeigen',
    item: {
      contactManager: {
        cta: 'Verwalter:In kontaktieren',
        headline: 'Verwalter:In kontaktieren',
        closeContent: 'X',
        sendCTA: 'abschicken',
        subject: 'Betreff*',
        message: 'Nachricht*',
        firstname: 'Vorname*',
        lastname: 'Nachname*',
        mail: 'E-Mail Adresse*',
        phone: 'Telefonnummer',
        dragAndDrop: {
          title: 'Datei anhängen',
          mobileTitle: 'Datei anhängen',
          text: 'Maximal 10 MB (Formate: jpg, jpeg, png, pdf)',
          error: {
            fileSize: 'Maximale Dateigröße überschritten',
            fileFormat: 'Nur jpg, jpeg, png und pdf Dateien erlaubt',
          },
        },
        success: {
          send: 'Ihre Kontaktanfrage wurde erfolgreich verschickt.',
        },
        error: {
          send: 'Ein Fehler beim Versand Ihrer Kontaktanfrage ist aufgetreten.',
        },
      },
    },
  },

  sepa: {
    headline: 'SEPA-Lastschriftmandat',
    name: 'Mietername *',
    namePlaceholder: 'Nachname, Vorname',
    birthday: 'Geburtstag *',
    contractNumber: 'Vertragsnummer *',
    streetAndNumber: 'Straße + Nr. *',
    postcodeAndCity: 'Postleitzahl und Ort *',
    flatNumber: 'Wohnungsnummer',

    accountOwner: 'Kontoinhaber *',
    accountStreetAndNumber: 'Straße + Nr. *',
    accountPostcodeAndCity: 'Postleitzahl und Ort *',

    iban: 'IBAN *',
    bic: 'BIC *',
    bank: 'Bank *',
    validFrom: 'Mandat gültig ab *',

    checkboxTitle: 'Rechtlicher Hinweis:',
    checkboxText:
      'Ich ermächtige/wir ermächtigen den oben genannten Gläubiger wiederkehrende Zahlungen von meinem/unserem Konto mittels SEPA-Basislastschrift einzuziehen. Zugleich weise ich/weisen wir mein/unser Kreditinstitut an, die vom Gläubiger auf mein Konto/unser Konto gezogenen Lastschriften einzulösen. *',

    hint1:
      'Hinweis: Ich kann/wir können innerhalb von acht Wochen, beginnend mit dem Belastungsdatum, die Erstattung des belasteten Betrages verlangen. Es gelten dabei die mit meinem Kreditinstitut vereinbarten Bedingungen.',

    hint2:
      'Die Mandatserteilung gilt bis zum schriftlichen Widerruf, wobei dieser 14 Tage vor Ablauf des Monats, in dem die letzte Abbuchung erfolgen soll, beim Vermieter vorliegen muss.',

    sendCTA: 'Absenden',
    mailSubject: 'Neues SEPA-Lastschriftmandat',

    estateSearch: {
      title: 'Wählen Sie Ihre Straße + Hausnummer:',
    },

    download: {
      cta: 'SEPA-Lastschriftmandat herunterladen',
      hint: 'Bitte senden Sie das ausgefüllte und unterschriebene SEPA-Lastschriftmandat an folgende Adresse:',
      address: 'Wilhelm Hilpert Hausverwaltung GmbH, Deutschmeisterstraße 16, 10367 Berlin',
    },
    notAvailable: {
      hint: 'Für diese Immobilie ist kein SEPA-Lastschriftmandat verfügbar. Bitte wenden Sie sich an Ihren Verwalter auf folgender Seite:',
      cta: 'Zu unseren Immobilien',
    },
    error: {
      generate: 'Ihr SEPA-Lastschriftmandat konnte nicht verarbeitet werden.',
      estateMissing: 'Bitte wählen Sie eine Immobilie aus.',
    },
  },

  documents: {
    headline: 'Dokumente',
  },

  team: {
    headline: 'Team',
    people: [
      {
        title: 'Geschäftsführung',
        items: [
          { salutation: 'Herr', firstname: 'Wilhelm', lastname: 'Hilpert' },
          {
            salutation: 'Frau',
            firstname: 'Mariola',
            lastname: 'Jähn',
            phone: '030 443 843 - 0',
          },
          {
            salutation: 'Herr',
            firstname: 'Lutz',
            lastname: 'Kutschbach',
            phone: '030 443 843 - 0',
          },
        ],
      },
      {
        title: 'Empfang',
        items: [
          {
            salutation: 'Frau',
            firstname: 'Grit',
            lastname: 'Rohr',
            mail: 'g.rohr@hilpert-hausverwaltung.de',
            phone: '030 443 843 - 0',
          },
        ],
      },
      {
        title: 'Personalwesen',
        items: [
          {
            salutation: 'Frau',
            firstname: 'Swantje',
            lastname: 'Pläth (Prokuristin)',
            mail: 's.plaeth@hilpert-hausverwaltung.de',
            phone: '030 443 843 - 110',
          },
        ],
      },
      {
        title: 'Buchhaltung',
        items: [
          {
            salutation: 'Frau',
            firstname: 'Joanna',
            lastname: 'Blume',
            mail: 'j.blume@hilpert-hausverwaltung.de',
            phone: '030 443 843 - 200',
          },
          {
            salutation: 'Herr',
            firstname: 'Ralf',
            lastname: 'Fiebig',
            mail: 'r.fiebig@hilpert-hausverwaltung.de',
            phone: '030 443 843 - 115',
          },
          {
            salutation: 'Herr',
            firstname: 'Sascha',
            lastname: 'Brösemann',
            mail: 's.broesemann@hilpert-hausverwaltung.de',
            phone: '030 443 843 - 260',
          },
          {
            salutation: 'Frau',
            firstname: 'Luise',
            lastname: 'Wichmann',
            mail: 'l.wichmann@hilpert-hausverwaltung.de',
            phone: '030 443 843 - 235',
          },
          {
            salutation: 'Frau',
            firstname: 'Huili',
            lastname: 'Zhao',
            mail: 'h.zhao@hilpert-hausverwaltung.de',
            phone: '030 443 843 - 135',
          },
        ],
      },
      {
        title: 'WEG - Verwalter:Innen',
        items: [
          {
            salutation: 'Herr',
            firstname: 'Robert',
            lastname: 'Rahn (Prokurist)',
            mail: 'r.rahn@hilpert-hausverwaltung.de',
            phone: '030 443 843 - 180',
          },
          {
            salutation: 'Herr',
            firstname: 'Dennis',
            lastname: 'Hübner (Prokurist)',
            mail: 'd.huebner@hilpert-hausverwaltung.de',
            phone: '030 443 843 - 250',
          },
          {
            salutation: 'Herr',
            firstname: 'Lars',
            lastname: 'Brandt (Prokurist)',
            mail: 'l.brandt@hilpert-hausverwaltung.de',
            phone: '030 443 843 - 190',
          },
          {
            salutation: 'Frau',
            firstname: 'Sylvia',
            lastname: 'Scheffler',
            mail: 's.scheffler@hilpert-hausverwaltung.de',
            phone: '030 443 843 - 210',
          },
          {
            salutation: 'Herr',
            firstname: 'David',
            lastname: 'Kalies',
            mail: 'd.kalies@hilpert-hausverwaltung.de',
            phone: '030 443 843 - 280',
          },
          {
            salutation: 'Herr',
            firstname: 'Stephan',
            lastname: 'Peters',
            mail: 's.peters@hilpert-hausverwaltung.de',
            phone: '030 443 843 - 170',
          },
          {
            salutation: 'Frau',
            firstname: 'Sandra',
            lastname: 'Heinze',
            mail: 's.heinze@hilpert-hausverwaltung.de',
            phone: '030 443 843 - 160',
          },
          {
            salutation: 'Herr',
            firstname: 'Alexander',
            lastname: 'Watin',
            mail: 'a.watin@hilpert-hausverwaltung.de',
            phone: '030 443 843 - 125',
          },
          {
            salutation: 'Frau',
            firstname: 'Franziska',
            lastname: 'Schneider',
            mail: 'f.schneider@hilpert-hausverwaltung.de',
            phone: '030 443 843 - 105',
          },
          {
            salutation: 'Frau',
            firstname: 'Josephin',
            lastname: 'Konetzka',
            mail: 'j.konetzka@hilpert-hausverwaltung.de',
            phone: '030 443 843 - 145',
          },
          {
            salutation: 'Herr',
            firstname: 'Vladislav',
            lastname: 'Oberheidtmann',
            mail: 'v.oberheidtmann@hilpert-hausverwaltung.de',
            phone: '030 443 843 - 205',
          },

          {
            salutation: 'Frau',
            firstname: 'Andrea',
            lastname: 'Großmann',
            mail: 'a.grossmann@hilpert-hausverwaltung.de',
            phone: '030 443 843 - 165',
          },
          {
            salutation: 'Herr',
            firstname: 'Leon',
            lastname: 'Schob',
            mail: 'l.schob@hilpert-hausverwaltung.de',
            phone: '030 443 843 - 155',
          },
          {
            salutation: 'Herr',
            firstname: 'Sebastian',
            lastname: 'Hill',
            mail: 's.hill@hilpert-hausverwaltung.de',
            phone: '030 443 843 - 225',
          },
        ],
      },
      {
        title: 'Schadens- und Versicherungsmanagement',
        items: [
          {
            salutation: 'Herr',
            firstname: 'Sascha',
            lastname: 'Fink',
            mail: 's.fink@hilpert-hausverwaltung.de',
            phone: '030 443 843 - 130',
            mobile: '0152 28569015',
          },
          {
            salutation: 'Herr',
            firstname: 'Timon',
            lastname: 'Lettau',
            mail: 't.lettau@hilpert-hausverwaltung.de',
            phone: '030 443 843 - 230',
            mobile: '0152 28570592',
          },
        ],
      },
      {
        title: 'Azubis',
        items: [
          {
            salutation: 'Frau',
            firstname: 'Malin',
            lastname: 'Maluck',
            mail: 'm.maluck@hilpert-hausverwaltung.de',
          },
          {
            salutation: 'Frau',
            firstname: 'Eve',
            lastname: 'Mohnke',
            mail: 'e.mohnke@hilpert-hausverwaltung.de',
          },
          {
            salutation: 'Herr',
            firstname: 'Nico',
            lastname: 'Dornberg',
            mail: 'n.dornberg@hilpert-hausverwaltung.de',
          },
          {
            salutation: 'Frau',
            firstname: 'Zuzanna',
            lastname: 'Januszewska',
            mail: 'z.januszewska@hilpert-hausverwaltung.de',
          },
          {
            salutation: 'Frau',
            firstname: 'Alexandra',
            lastname: 'Ilyina',
            mail: 'a.ilyina@hilpert-hausverwaltung.de',
          },
        ],
      },
    ],
  },

  career: {
    headline: 'Karriere',
    hero: {
      text1: 'SEIT 1997 DAS BESTE AUS ZWEI WELTEN.',
      text2: 'EIN BERLINER UNTERNEHMEN MIT BAYERISCHEN WURZELN.',
    },
    intro:
      'Die Wilhelm Hilpert Hausverwaltung GmbH ist der ideale Partner im Bereichen der Verwaltung von Wohneigentum, Mietwohnungen und Gewerbeflächen, sowie weitere immobilienbezogene Dienstleistungen.',
    videoSection: {
      button: 'Unsere offenen Stellen',
    },
    goals: {
      headline: 'Unsere Ziele',
      item1: {
        title: 'HOHER STANDARD UNSERER OBJEKTE',
        text: 'Alle Immobilien, die sich in unserer Verwaltung befinden, bringen höchsten Standard mit sich. Mit unserem gesamten Team sorgen wir dafür, dass Neubauten und Objekte mit viel Geschichte, diesen Standard beibehalten.',
      },
      item2: {
        title: 'FUNKTIONIERENDE PROZESSE FÜR UNSER TEAM',
        text: 'Durch etablierte Herangehensweisen, die Platz für Optimierungen und individuelle Arbeitsweisen zulassen sowie enge Zusammenarbeit und Kollegialität, lösen wir Herausforderungen und schaffen ein gemeinsames Erfolgsrezept.',
      },
      item3: {
        title1: 'UNSERE',
        title2: 'SERVICEQUALITÄT',
        text: 'Neben den Immobilien steht der Service für Bewohner und Eigentümer im Fokus unserer Arbeit.',
      },
      text: 'In Kooperation mit der HILPERT AG in Bayern liegt unser Schwerpunkt in der Verwaltung von Wohnungseigentümergemeinschaften.',
    },
    applyCTASection: {
      title: '„Mit der Wilhelm Hilpert Hausverwaltung GmbH als Arbeitgeber auf der sicheren Seite“',
      cta: 'Initiativbewerbung einreichen',
    },
    ourValues: {
      headline: 'Unsere Werte',
      items: [
        {
          title: 'Respektvoll',
          text: 'Wir stehen für respektvollen Umgang gegenüber jedem, der mit unserem Unternehmen verbunden ist. Deshalb pflegen wir eine offene Kommunikation.',
        },
        {
          title: 'Mutig',
          text: 'Wir sind kreativ bei unserer Arbeit und bieten unseren Kunden einen Mehrwert. Veränderungen gegenüber sind wir offen und nutzen die Chancen, die sich daraus ergeben.',
        },
        {
          title: 'Einzigartig',
          text: 'Mieter- & Eigentümerorientierte Lösungen stehen im Vordergrund unserer täglichen Arbeit. Unsere Leistungen zeichnen sich durch Know-how und Erfahrung aus. Die Zufriedenheit unserer Mieter:Innen und Eigentümer:Innen steht für uns im Fokus.',
        },
        {
          title: 'Teamfähigkeit',
          text: 'Unter Team verstehen wir Kollegialität, Zusammenhalt, Verständnis und an einem Strang zu ziehen, um ein gemeinsames Ziel zu erreichen.',
        },
        {
          title: 'Innovativ',
          text: 'Wir fördern und unterstützen kreatives Denken, wir arbeiten zukunftsorientiert und entdecken neue Möglichkeiten.',
        },
        {
          title: 'Fehlerkultur',
          text: 'Wir gehen respektvoll mit Fehlern um. Wir stehen zu ihnen und nutzen sie, um daraus zu lernen und besser zu werden.',
        },
        {
          title: 'Motivation',
          text: 'Jeder Einzelne unseres Teams bringt mit seinem Engagement und Know-how auch die richtige Portion Leidenschaft mit, um Themen erfolgreich abzuschließen.',
        },
        {
          title: 'Gemeinsam',
          text: 'Ein respektvoller Umgang im Ton und in der Kommunikation ist uns wichtig. Wir nehmen Rücksicht und stehen für eine offene, ehrliche und sachliche Kommunikation.',
        },
      ],
    },
    timeline: {
      headline: '16 GRÜNDE TEAMMITGLIED BEI DER WILHELM HILPERT HAUSVERWALTUNG GMBH ZU WERDEN',
      points: [
        'Seit 1997 in Berlin',
        'Bürolage in Lichtenberg',
        'Langjährige Betreuung der Objekte',
        'Mehr als 5.000 Verwaltungseinheiten',
        'Tätigkeiten in der gesamten Metropolregion Berlin',
        'Objekte mit Geschichte & Tradition sowie viele Neubauten',
        'Fortlaufende Digitalisierung des Unternehmens',
        '30 Kolleg*innen im Unternehmen',
        'Zertifiziertes Ausbildungsunternehmen',
        'Offenes und lockeres Team',
        'Flexible Arbeitszeiten',
        'Betriebliche Krankenversicherung',
        'Sonderzahlungen',
        'BVG-Firmenticket',
        '1x pro Woche kostenfreies Team-Mittagessen',
        'Frischer Obstkorb, Wasser, Heißgetränke und Snacks zur kostenfreien Verfügung',
      ],
    },
    openPositions: {
      headline: 'Unsere offenen Stellen',
      text: 'Als langjährig bestehende Hausverwaltung in Berlin suchen wir nach neuen Teammitgliedern. Hier finden Sie unsere aktuellen Stellenangebote.',
      intro:
        'Du suchst eine spannende berufliche Herausforderung in der Immobilienverwaltung? Werde Teil unseres erfolgreichen Teams bei der Hilpert Hausverwaltung und trage aktiv zur Zufriedenheit unserer Mieter*innen und Eigentümer*innen bei. Gemeinsam gestalten wir ein optimales Wohn- und Immobilienmanagement.',
      initiativeApplication: 'Initiativbewerbung',
      gender: '(w/m/d)',
      tasks: 'DEINE AUFGABEN:',
      important: 'DEIN PROFIL:',
      ourOfferings: 'DEINE BENEFITS:',
    },
    apply: {
      headline: 'Initiativbewerbung',
      desiredEntryDate: 'Wunsch-Eintrittsdatum (DD.MM.YYYY)',
      salaryExpectation: 'Gehaltsvorstellung',
      cta: 'Bewerbung absenden',
      dragAndDrop: {
        title: 'DRAG & DROP',
        mobileTitle: 'DATEI AUSWÄHLEN',
        text: 'Eigene Dokumente hier hochlagden',
        hint: '(Lebenslauf, Zeugnisse, u. ä.)',
        error: {
          fileSize: 'Maximale Dateigröße von 10 MB überschritten.',
          fileFormat: 'Ungültiges Dateiformat. Erlaubt: PDF, doc, docx, jpg, jpeg, png',
        },
      },
      submit: {
        success: 'Ihre Bewerbung wurde erfolgreich verschickt.',
        error: 'Leider ist ein technischer Fehler aufgetreten.',
        subject: 'Neue Bewerbung',
      },
    },
    faq: {
      headline: 'FAQ',
      items: [
        {
          title: 'Frage1',
          text: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.',
        },
        {
          title: 'Frage2',
          text: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.',
        },
        {
          title: 'Frage3',
          text: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.',
        },
      ],
    },
    ourTeam: {
      headline: 'Unser Team',
      text: 'Für alle Fragen rund um Ihre Wohnung/Immobilie sind wir die richtigen Ansprechpartner. Gerne helfen wir Ihnen weiter.',
      people: [
        {
          name: 'L. Brandt',
          phone: '030/44 38 43 190',
          mail: 'l.brandt@hilpert-hausverwaltung.de',
        },
        {
          name: 'L. Brandt',
          phone: '030/44 38 43 190',
          mail: 'l.brandt@hilpert-hausverwaltung.de',
        },
        {
          name: 'L. Brandt',
          phone: '030/44 38 43 190',
          mail: 'l.brandt@hilpert-hausverwaltung.de',
        },
        {
          name: 'L. Brandt',
          phone: '030/44 38 43 190',
          mail: 'l.brandt@hilpert-hausverwaltung.de',
        },
        {
          name: 'L. Brandt',
          phone: '030/44 38 43 190',
          mail: 'l.brandt@hilpert-hausverwaltung.de',
        },
        {
          name: 'L. Brandt',
          phone: '030/44 38 43 190',
          mail: 'l.brandt@hilpert-hausverwaltung.de',
        },
      ],
    },
  },

  imprint: {
    headline: 'Impressum',
  },

  dataProtection: {
    headline: 'Datenschutz',
  },

  admin: {
    login: {
      headline: 'Admin',
      cta: 'Login',
      error: 'Fehlerhaftes Passwort.',
    },
    nav: {
      estates: 'Immobilien',
      managers: 'Managers',
      positions: 'Offene Stellen',
    },
    estates: {
      create: {
        headline: 'Neue Immobilie anlegen:',
      },
      list: {
        headline: 'Bestehende Immobilien',
      },
      form: {
        number: 'Nummer',
        city: 'Stadt',
        street: 'Straße',
        postcode: 'Postleitzahl',
        manager: 'Manager',
        phone: 'Telefonnummer',
        accountingPerson: 'Buchhalter:In',
        emergencyNumber: 'Notfallnummer',
        believerId: 'Gläubiger-ID',
        iban: 'IBAN',
        cta: 'Speichern',
        removeCTA: 'Löschen',
        success: 'Erfolgreich gespeichert!',
        error: 'Fehler beim Speichern der Daten.',
        removeError: 'Fehler beim Löschen der Immobilie.',
        dragAndDrop: {
          title: 'DRAG & DROP',
          mobileTitle: 'BILD AUSWÄHLEN',
          text: 'Bild für die Immobilie hochladen',
          error: {
            fileSize: 'Maximale Dateigröße von 10 MB überschritten.',
            fileFormat: 'Ungültiges Dateiformat. Erlaubt: jpg, jpeg, png',
          },
        },
      },
    },
    managers: {
      create: {
        headline: 'Neuen Manager anlegen:',
      },
      list: {
        headline: 'Bestehende Manager',
      },
      form: {
        cta: 'Speichern',
        deleteCTA: 'Löschen',
        success: 'Erfolgreich gespeichert!',
        error: 'Fehler beim Speichern der Daten.',
        removeError: 'Fehler beim Löschen des Managers.',
      },
    },
    caretakerCompany: {
      create: {
        headline: 'Neue Hauswartsfirma erstellen:',
      },
      list: {
        headline: 'Bestehende Hauswartsfirmen',
      },
      form: {
        name: 'Name',
        cta: 'Speichern',
        deleteCTA: 'Löschen',
        success: 'Erfolgreich gespeichert!',
        error: 'Fehler beim Speichern der Daten.',
        removeError: 'Fehler beim Löschen der Hauswartsfirma.',
      },
    },
    positions: {
      create: {
        headline: 'Neuen Stelle anlegen:',
      },
      list: {
        headline: 'Bestehende Stellen',
      },
      form: {
        title: 'Titel',
        takeYourChance: '"Nutze deine Chance!"-Intro',
        tasks: 'DEINE AUFGABEN:',
        important: 'DEIN PROFIL:',
        ourOfferings: 'DEINE BENEFITS:',
        cta: 'Speichern',
        removeCTA: 'Löschen',
        success: 'Erfolgreich gespeichert!',
        error: 'Fehler beim Speichern der Daten.',
        removeError: 'Fehler beim Löschen des Managers.',
      },
    },
  },

  generic: {
    salutation: 'Anrede',
    firstname: 'Vorname',
    lastname: 'Nachname',
    street: 'Straße',
    postcode: 'Postleitzahl',
    city: 'Stadt',
    phone: 'Telefon',
    mobile: 'Mobil',
    fax: 'Fax',
    mail: 'E-Mail',
    password: 'Password',
    manager: 'Verwalter:In',
    caretakerCompany: 'Hauswartsfirma',
    emergencyService: 'NOTDIENST',
  },

  UI: {
    autocomplete: {
      searchError: 'Suche konnte nicht ausgeführt werden.',
      searchEmpty: 'Keine Ergebnisse',
    },
  },
}

module.exports = {
  translation,
  langKey: 'DE',
}
