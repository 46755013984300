import styled from 'styled-components'
import Hero from '../UI/Hero/Hero'

export const StyledHome = styled.div``

export const CustomHero = styled(Hero)`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  @media (max-width: ${({ theme }) => theme.breakpoint.lg}) {
    min-height: 800px;
  }
`

export const Logo = styled.img`
  height: 250px;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    height: 150px;
  }
`

export const HeroText = styled.div`
  color: ${({ theme }) => theme.color.white};
  text-align: center;
  background-color: rgba(63, 72, 121, 0.4);
  padding: 25px 50px;
  border-radius: ${({ theme }) => theme.border.radius};
  margin: 50px 0px;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    padding: 25px;
  }
`
export const HeroText2 = styled.div`
  color: ${({ theme }) => theme.color.white};
  position: absolute;
  width: 100%;
  left: 0px;
  bottom: 0px;
  text-align: center;
  margin: 50px 0px;
`

export const CareerSection = styled.div`
  height: 50vh;
  background-position: center -200px;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 15px 0;
`

export const ModalContent = styled.div`
  text-align: center;
  padding-top: 25px;
`

export const SmallText = styled.div`
  * {
    font-size: 20px;
  }
  font-size: 20px;
`
