import {
  StyledHome,
  CustomHero,
  Logo,
  HeroText,
  HeroText2,
  CareerSection,
  ButtonWrapper,
  ModalContent,
  SmallText,
} from './Home.Styled'
import heroImage from '../../assets/image/home/hero.jpg'
import logo from '../../assets/image/logo.png'
import { useSelector } from 'react-redux'
import Separator from '../UI/Separator/Separator'
import Text from '../UI/Text/Text'
import Container from '../UI/Container/Container'
import Layout from '../UI/Layout/Layout'
import Search from './Search/Search'
// import Overview from "./Overview/Overview";
import AboutUs from './AboutUs/AboutUs'
import Office from './Office/Office'
import Goals from './Goals/Goals'
import OurValues from './OurValues/OurValues'
import OfficeHours from './OfficeHours/OfficeHours'
import careerImage from '../../assets/image/career/hero.png'
import Button from '../UI/Button/Button'
import Modal from '../UI/Modal/Modal'
import { useState } from 'react'

const Home = () => {
  const translation = useSelector((s) => s.translation)
  const theme = useSelector((s) => s.theme)
  const [open, setOpen] = useState(true)

  const toggle = () => setOpen(!open)

  return (
    <>
      <Layout>
        {/* <CareerSection style={{ backgroundImage: `url(${careerImage})`, height: '100px' }}>
          <Button
            small
            width="fit-content"
            text="Unsere offenen stellen"
            href={'/career#open-positions'}
          />
        </CareerSection> */}
        <StyledHome>
          <CustomHero img={heroImage}>
            <Container>
              <Logo src={logo} />
              <HeroText>
                <Text
                  text={translation.home.title}
                  color={theme.color.white}
                  font="bold"
                  align={'center'}
                />
                <Text
                  text={translation.home.text}
                  color={theme.color.white}
                  margin="20px 0 0 0"
                  align={'center'}
                />
              </HeroText>
              <HeroText2>
                <Separator color={theme.color.white} />
                {translation.career.hero.text1}
                <br />
                {translation.career.hero.text2}
              </HeroText2>
            </Container>
          </CustomHero>
          <Search />
          <Goals />
          <CareerSection style={{ backgroundImage: `url(${careerImage})` }}>
            <Button text={translation.career.videoSection.button} href={'/career#open-positions'} />
          </CareerSection>
          <OurValues />
          {/* <Overview /> */}
          <AboutUs />
          <OfficeHours />
          <Office />
        </StyledHome>
      </Layout>
      <Modal small open={open} close={toggle} noClose>
        <ModalContent>
          <strong>WIR SIND UMGEZOGEN!</strong>
          <br />
          <br />
          <SmallText>
            <strong>Unsere neue Adresse:</strong> Deutschmeisterstraße 16, 10367 Berlin
          </SmallText>
        </ModalContent>
        <br />
        <ButtonWrapper>
          <Button small text="schließen" onClick={toggle} />
        </ButtonWrapper>
      </Modal>
    </>
  )
}

export default Home
