import styled from 'styled-components'

export const StyledButton = styled.button`
  color: ${({ theme, color }) => color || theme.color.white};
  background-color: ${({ theme, backgroundColor }) => backgroundColor || theme.color.blue};
  width: ${({ width, small }) => (width || small ? 'fit-content' : '300px')};
  padding: ${({ small }) => (small ? '5px 50px' : '10px 10px')};
  border-radius: ${({ theme }) => theme.border.radius};
  margin: ${({ margin }) => margin || '0px'};
  border: none;
  text-transform: uppercase;
  display: ${({ display }) => display || 'inline-block'};
  text-align: center;
  font-size: ${({ theme, small }) => (small ? theme.size.sm : theme.size.md)};
  cursor: pointer;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    font-size: ${({ theme }) => theme.size.sm};
  }
`

export const StyledButtonAnchor = styled.a`
  color: ${({ theme, color }) => color || theme.color.white};
  background-color: ${({ theme, backgroundColor }) => backgroundColor || theme.color.blue};
  width: ${({ width, small }) => (width || small ? 'fit-content' : '300px')};
  padding: ${({ small }) => (small ? '5px 50px' : '10px 10px')};
  border-radius: ${({ theme }) => theme.border.radius};
  margin: ${({ margin }) => margin || '0px'};
  border: none;
  text-transform: uppercase;
  text-decoration: none;
  display: ${({ display }) => display || 'inline-block'};
  text-align: center;
  font-size: ${({ theme, small }) => (small ? theme.size.sm : theme.size.md)};
  cursor: pointer;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    font-size: ${({ theme }) => theme.size.sm};
  }
`
