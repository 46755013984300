import {
  StyledModal,
  Core,
  Header,
  Title,
  Close,
  Content,
  Background,
  Footer,
} from './Modal.Styled'
import { useEffect, useCallback } from 'react'
import React from 'react'

const Modal = ({
  title,
  close,
  children,
  footerContent,
  closeContent = 'close',
  onEnter,
  preventEnter = false,
  open = false,
  noClose = false,
  small = false,
}) => {
  const escFunction = useCallback(
    (event) => {
      if (event.key === 'Escape') {
        close()
      } else if (event.key === 'Enter' && onEnter) {
        onEnter()
        event.preventDefault()
      } else if (event.key === 'Enter' && preventEnter) {
        event.preventDefault()
      }
    },
    [close, onEnter, preventEnter],
  )

  const matchesConditions = () => children && typeof close === 'function' && open === true

  useEffect(() => {
    if (matchesConditions()) {
      document.addEventListener('keydown', escFunction)
      document.body.style.overflow = 'hidden'
      return () => {
        document.body.style.overflow = 'unset'
        document.removeEventListener('keydown', escFunction)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [escFunction])

  if (!matchesConditions()) return <></>
  return (
    <StyledModal>
      <Core small={small}>
        {!noClose && !!title && (
          <Header>
            <Title>{title}</Title>
            <Close onClick={close}>{closeContent}</Close>
          </Header>
        )}
        <Content>{children}</Content>
        {footerContent && <Footer>{footerContent}</Footer>}
      </Core>
      <Background onClick={close} />
    </StyledModal>
  )
}

export default Modal
